// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../plugins/perfect-scrollbar/perfect-scrollbar.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./structure.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../plugins/highlight/styles/monokai-sublime.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    Importing highlight.js CSS code 

    Just for demo purpose only
	
	@Note:-  monokai-sublime.css stylesheet is for highliting the code;

*/
`, "",{"version":3,"sources":["webpack://./src/assets/css/plugins.css"],"names":[],"mappings":"AAGA;;;;;;;CAOC","sourcesContent":["@import url(\"main.css\");\n@import url(\"../../plugins/perfect-scrollbar/perfect-scrollbar.css\");\n@import url(\"structure.css\");\n/*\n    Importing highlight.js CSS code \n\n    Just for demo purpose only\n\t\n\t@Note:-  monokai-sublime.css stylesheet is for highliting the code;\n\n*/\n@import \"../../plugins/highlight/styles/monokai-sublime.css\";\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
