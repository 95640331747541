// get host
export const GET_HOST = "GET_HOST";

//Handle Open Close Dialog
export const OPEN_HOST_DIALOG = "OPEN_HOST_DIALOG";

// handle close Dialog
export const CLOSE_HOST_DIALOG = "CLOSE_HOST_DIALOG";

// handel Edit host Request
export const EDIT_HOST = "EDIT_HOST";

//  create fake host
export const CREATE_HOST = "CREATE_HOST";

//  host get profile
export const GET_HOST_PROFILE = "GET_HOST_PROFILE";

// host disable 
export const HOST_DISABLE = "HOST_DISABLE"

//  get host history

export const GET_HOST_HISTORY = "GET_HOST_HISTORY"


// UPDATE_HOST_COIN 
export const UPDATE_HOST_COIN = "UPDATE_HOST_COIN";